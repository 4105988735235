<template>
	<div class="">
    <div>
        {{this.$route.params.price}}元
      </div>

	</div>
</template>

<script>
    export default {}
</script>

<style>


</style>
